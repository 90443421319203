/**
 * Syntax highlighting styles
 */
.highlight {
  background: $grey-color;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: $grey-color;
    color: $text-color-light;
  }
  .c{color:#7f9f7f}
  .err{color:#e37170;background-color:#3d3535}
  .g{color:#7f9f7f}
  .k{color:#f0dfaf}
  .l{color:#ccc}
  .n{color:#dcdccc}
  .o{color:#f0efd0}
  .x{color:#ccc}
  .p{color:#41706f}
  .cm{color:#7f9f7f}
  .cp{color:#7f9f7f}
  .c1{color:#7f9f7f}
  .cs{color:#cd0000;font-weight:bold}
  .gd{color:#cd0000}
  .ge{color:#ccc;font-style:italic}
  .gr{color:red}
  .gh{color:#dcdccc;font-weight:bold}
  .gi{color:#00cd00}
  .go{color:gray}
  .gp{color:#dcdccc;font-weight:bold}
  .gs{color:#ccc;font-weight:bold}
  .gu{color:purple;font-weight:bold}
  .gt{color:#0040D0}
  .kc{color:#dca3a3}
  .kd{color:#ffff86}
  .kn{color:#dfaf8f;font-weight:bold}
  .kp{color:#cdcf99}
  .kr{color:#cdcd00}
  .kt{color:#00cd00}
  .ld{color:#cc9393}
  .m{color:#8cd0d3}
  .s{color:#cc9393}
  .na{color:#9ac39f}
  .nb{color:#efef8f}
  .nc{color:#efef8f}
  .no{color:#ccc}
  .nd{color:#ccc}
  .ni{color:#c28182}
  .ne{color:#c3bf9f;font-weight:bold}
  .nf{color:#efef8f}
  .nl{color:#ccc}
  .nn{color:#8fbede}
  .nx{color:#ccc}
  .py{color:#ccc}
  .nt{color:#9ac39f}
  .nv{color:#dcdccc}
  .ow{color:#f0efd0}
  .w{color:#ccc}
  .mf{color:#8cd0d3}
  .mh{color:#8cd0d3}
  .mi{color:#8cd0d3}
  .mo{color:#8cd0d3}
  .sb{color:#cc9393}
  .sc{color:#cc9393}
  .sd{color:#cc9393}
  .s2{color:#cc9393}
  .se{color:#cc9393}
  .sh{color:#cc9393}
  .si{color:#cc9393}
  .sx{color:#cc9393}
  .sr{color:#cc9393}
  .s1{color:#cc9393}
  .ss{color:#cc9393}
  .bp{color:#efef8f}
  .vc{color:#efef8f}
  .vg{color:#dcdccc}
  .vi{color:#ffffc7}
  .il{color:#8cd0d3}

}
